import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../../workflow-utilities";

export const craftVisionWorkflowName = "Craft a compelling vision";

export const craftVisionWorkflow = buildLocalWorkflow({
    name: craftVisionWorkflowName,
    workflowId: WorkflowIdentifier.CraftVision,
    type: WorkflowType.Journey,
    time: "3 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Craft an organisation vision that is compelling to those who work in the business, but also meets the owner's aspirations.",
    articleSlug: ImplementationKitArticle.CraftVisionOutcomes,
    wrapUpSlug: ImplementationKitArticle.CraftVisionWrapUp,
    category: OrganisationCategoryValue.Foundation,
    scope: WorkflowScope.Team,
    featuresToEnable: [FeatureName.PurposeAndVision],
    moduleFeaturesRequired: [FeatureName.PurposeAndVision],
    wrapUpGuidedTourIdentifier: TourIdentifier.VisionStatementWrapUpTour,
    ordinal: 40,
    compulsoryPrerequisites: [WorkflowIdentifier.AlignOwnersVision, WorkflowIdentifier.Purpose],
    workflows: [
        createActivityBriefWorkflow(craftVisionWorkflowName, WorkflowIdentifier.CraftVision, undefined, ImplementationKitArticle.CraftVisionMeetingDescription, ImplementationKitArticle.CraftVisionMeetingPreWork),
        ...createWorkshopScheduleWorkflows(craftVisionWorkflowName, WorkflowIdentifier.CraftVision),
    ],
});
