import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../../workflow-utilities";

export const setValuesWorkflowName = "Set the values";

export const setValuesWorkflow = buildLocalWorkflow({
    name: setValuesWorkflowName,
    workflowId: WorkflowIdentifier.SetValues,
    type: WorkflowType.Journey,
    time: "4 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Set some 'real' values for your organisation that can drive decision making and behaviours across your organisation.",
    articleSlug: ImplementationKitArticle.SetValuesOutcomes,
    wrapUpSlug: ImplementationKitArticle.SetValuesWrapUp,
    category: OrganisationCategoryValue.Foundation,
    scope: WorkflowScope.Team,
    featuresToEnable: [FeatureName.CompassValuesConstitution],
    moduleFeaturesRequired: [FeatureName.CompassValuesConstitution],
    wrapUpGuidedTourIdentifier: TourIdentifier.SetValuesWrapUpTour,
    ordinal: 50,
    compulsoryPrerequisites: [WorkflowIdentifier.Purpose, WorkflowIdentifier.CraftVision],
    workflows: [
        createActivityBriefWorkflow(setValuesWorkflowName, WorkflowIdentifier.SetValues, undefined, ImplementationKitArticle.ValuesMeetingDescription, ImplementationKitArticle.ValuesMeetingPreWork),
        ...createWorkshopScheduleWorkflows(setValuesWorkflowName, WorkflowIdentifier.SetValues),
    ],
});
