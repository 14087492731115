import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { EventCadenceCycle } from "@common/ADAPT.Common.Model/organisation/event-cadence-cycle";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buttonPreset } from "@common/ux/button/button-preset";
import { ISetCadenceRunData } from "@org-common/lib/schedule/schedule.interface";
import { IScheduledRecurrence } from "@org-common/lib/schedule/schedule-recurrence/schedule-recurrence.interface";
import { buildLocalWorkflow, LocalWorkflowStep } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { createActivityBriefWorkflow, createActivityBriefWorkflowStep, createWorkshopScheduleWorkflows } from "../../workflow-utilities";

export interface ISetCadenceStepData {
    eventType: EventTypePreset;
}

export const EventTypePresetNames: Record<EventTypePreset, string> = {
    [EventTypePreset.SetFirstObjectives]: "Set first objectives",
    [EventTypePreset.AnnualStrategy]: "Annual strategy",
    [EventTypePreset.QuarterlyStrategy]: "Quarterly strategy",
    [EventTypePreset.MonthlyStrategy]: "Monthly strategy",
    [EventTypePreset.OKRCheckIn]: "OKR check-in",
};

export const establishCadenceWorkflowName = "Establish business cadence";

export function buildEstablishCadenceWorkflow(includeCalendarIntegration: boolean, includeSetFirstObjectives: boolean) {
    const calendarIntegrationSteps: LocalWorkflowStep[] = [];
    if (includeCalendarIntegration) {
        calendarIntegrationSteps.push({
            name: "Calendar integration",
            ordinal: 0,
            allowBack: true,
            canSkip: true,
            componentSelector: "adapt-calendar-integration-step",
        });
    }

    const ordinalOffset = includeCalendarIntegration ? 1 : 0;

    const eventTypePresets = [
        ...(includeSetFirstObjectives ? [EventTypePreset.SetFirstObjectives] : []),
        EventTypePreset.AnnualStrategy,
        EventTypePreset.QuarterlyStrategy,
        EventTypePreset.MonthlyStrategy,
        EventTypePreset.OKRCheckIn,
    ];

    const steps = eventTypePresets.map((eventType, idx) => {
        const meetingText = eventType === EventTypePreset.SetFirstObjectives || eventType === EventTypePreset.AnnualStrategy
            ? "meeting"
            : "meetings";
        return {
            name: `${EventTypePresetNames[eventType]} ${meetingText}`,
            ordinal: ordinalOffset + idx + 1,
            allowBack: true,
            canSkip: false,
            componentSelector: "adapt-set-cadence-step",
            customData: { eventType } as ISetCadenceStepData,
        } as LocalWorkflowStep;
    });

    return buildLocalWorkflow({
        name: establishCadenceWorkflowName,
        workflowId: WorkflowIdentifier.PlanYourStrategyCadence,
        type: WorkflowType.Workflow,
        dialogWidth: WorkflowDialogWidth.ExtraLarge,
        iconStyle: "fal fa-chess-queen-piece",
        articleSlug: ImplementationKitArticle.EstablishCadenceOutcomes,
        wrapUpSlug: ImplementationKitArticle.EstablishCadenceWrapUp,
        isStateless: true,
        featuresToEnable: [FeatureName.StewardshipWorkKanban, FeatureName.StewardshipWorkMeetings],
        ordinal: 10,
        runData: {
            eventTypePresets,
            scheduledPresets: new Map<EventTypePreset, IScheduledRecurrence>(),
            deletedEntities: [],
            // this will be populated with the real eventCadenceCycle by set-cadence-cycle-step
            eventCadenceCycle: new EventCadenceCycle(),
        } as ISetCadenceRunData,
        steps: [
            createActivityBriefWorkflowStep(establishCadenceWorkflowName, ImplementationKitArticle.EstablishCadenceIntro),
            ...calendarIntegrationSteps,
            {
                name: "Set cadence cycle",
                ordinal: ordinalOffset + 0,
                allowBack: true,
                canSkip: false,
                articleSlug: ImplementationKitArticle.EstablishCadenceSetCadenceCycle,
                articlePosition: WorkflowStepGuidancePosition.Left,
                componentSelector: "adapt-set-cadence-cycle-step",
            },
            ...steps,
            {
                name: "Review",
                ordinal: ordinalOffset + steps.length,
                allowBack: true,
                canSkip: false,
                componentSelector: "adapt-review-cadence-step",
                workflowStepNextText: "Save cadence and create meetings",
                workflowStepNextIcon: `${buttonPreset.save.iconClass} fa-fw`,
            },
            {
                name: "Wrap up",
                ordinal: ordinalOffset + steps.length + 1,
                allowBack: false,
                canSkip: false,
                componentSelector: "adapt-finish-cadence-step",
            },
        ],
    });
}

export const planYourStrategyCadenceWorkflow = buildEstablishCadenceWorkflow(false, true);

export const establishCadenceWorkflow = buildLocalWorkflow({
    name: establishCadenceWorkflowName,
    workflowId: WorkflowIdentifier.EstablishBusinessCadence,
    type: WorkflowType.Journey,
    time: "1 hour",
    people: "Leadership team",
    mindset: "Collaborative",
    overview: "Establish how your organisation will use a regular cadence to drive, monitor & implement your strategy.",
    articleSlug: ImplementationKitArticle.EstablishCadenceOutcomes,
    category: OrganisationCategoryValue.ImplementStrategy,
    wrapUpSlug: ImplementationKitArticle.EstablishCadenceWrapUp,
    compulsoryPrerequisites: [WorkflowIdentifier.StrategicGoals],
    featuresToEnable: [FeatureName.StewardshipObjectives],
    moduleFeaturesRequired: [FeatureName.StewardshipWorkCadence],
    ordinal: 10,
    workflows: [
        createActivityBriefWorkflow(establishCadenceWorkflowName, WorkflowIdentifier.EstablishBusinessCadence, undefined, ImplementationKitArticle.EstablishCadenceMeetingDescription, ImplementationKitArticle.EstablishCadenceMeetingPreWork),
        ...createWorkshopScheduleWorkflows(establishCadenceWorkflowName, WorkflowIdentifier.EstablishBusinessCadence),
    ],
});
