import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { TourIdentifier } from "../../tours/tour-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../workflow-utilities";

export const identifyStrategicThemesWorkflowName = "Identify strategic themes";

export const identifyStrategicThemesWorkflow = buildLocalWorkflow({
    name: identifyStrategicThemesWorkflowName,
    workflowId: WorkflowIdentifier.StrategicThemes,
    type: WorkflowType.Journey,
    time: "4 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Strategic themes come from analysing the data that comes out of the vision and identifying some commonalities.",
    category: OrganisationCategoryValue.BuildStrategy,
    featuresToEnable: [FeatureName.StrategyBoard],
    moduleFeaturesRequired: [FeatureName.StrategyBoard],
    articleSlug: ImplementationKitArticle.IdentifyStrategicThemesOutcomes,
    wrapUpSlug: ImplementationKitArticle.IdentifyStrategicThemesWrapUp,
    wrapUpGuidedTourIdentifier: TourIdentifier.StrategicThemesWrapUpTour,
    compulsoryPrerequisites: [WorkflowIdentifier.Purpose, WorkflowIdentifier.CraftVision, WorkflowIdentifier.SetValues],
    ordinal: 50,
    workflows: [
        createActivityBriefWorkflow(identifyStrategicThemesWorkflowName, WorkflowIdentifier.StrategicThemes, undefined, ImplementationKitArticle.IdentifyStrategicThemesMeetingDescription, ImplementationKitArticle.IdentifyStrategicThemesMeetingPreWork),
        ...createWorkshopScheduleWorkflows(identifyStrategicThemesWorkflowName, WorkflowIdentifier.StrategicThemes),
    ],
});
