<div class="d-flex flex-column align-items-center my-4">
    @if (ownerAlignmentFeatureEnabled) {
        <adapt-display-seeds [seedType]="SeedEntity.Vision"></adapt-display-seeds>
        <button *adaptIfAuthorised="CompassGuidingPhilosophyEdit"
                class="btn btn-primary mt-3 px-4"
                [adaptBlockingClick]="editOwnersAlignment"
                role="button">Update vision
        </button>
    } @else {
        <span>This feature is enabled only for the Growth+ platform subscription.</span>
    }
</div>
