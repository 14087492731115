import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { TourIdentifier } from "../../tours/tour-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../workflow-utilities";

export const setStrategicGoalsWorkflowName = "Set strategic goals";

export const setStrategicGoalsWorkflow = buildLocalWorkflow({
    name: setStrategicGoalsWorkflowName,
    workflowId: WorkflowIdentifier.StrategicGoals,
    type: WorkflowType.Journey,
    time: "3 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Understand what and how to set your strategic goals - goals with a 3-5 year time-frame that will guide you to your vision.",
    category: OrganisationCategoryValue.BuildStrategy,
    featuresToEnable: [FeatureName.StrategicGoals, FeatureName.StrategyBoard],
    moduleFeaturesRequired: [FeatureName.StrategicGoals],
    articleSlug: ImplementationKitArticle.SetStrategicGoalsOutcomes,
    wrapUpSlug: ImplementationKitArticle.SetStrategicGoalsWrapUp,
    wrapUpGuidedTourIdentifier: TourIdentifier.StrategicGoalsWrapUpTour,
    ordinal: 90,
    compulsoryPrerequisites: [WorkflowIdentifier.StrategicThemes],
    workflows: [
        createActivityBriefWorkflow(setStrategicGoalsWorkflowName, WorkflowIdentifier.StrategicGoals, undefined, ImplementationKitArticle.SetStrategicGoalsMeetingDescription, ImplementationKitArticle.SetStrategicGoalsMeetingPreWork),
        ...createWorkshopScheduleWorkflows(setStrategicGoalsWorkflowName, WorkflowIdentifier.StrategicGoals),
    ],
});
