import { formatCurrency } from "@angular/common";
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { CoachAccessService } from "@common/coach-access/coach-access.service";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { CoachRequestType, ICoachRequest } from "@common/payment-processing/payment-processing.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { ICoachOption } from "../coach-option.interface";

@Component({
    selector: "adapt-coach-access-session-select",
    templateUrl: "./coach-access-session-select.component.html",
    styleUrls: ["./coach-access-session-select.component.scss"],
})
export class CoachAccessSessionSelectComponent extends BaseComponent implements OnInit {
    private readonly welcomeSession: ICoachOption = {
        name: "FREE Welcome session",
        cost: 0.00,
        minutes: 30,
        hours: 0,
        adjustableTime: false,
        type: CoachRequestType.WelcomeSession,
        calendlyUri: "welcome-session",
    };
    // private readonly scopingSession: ICoachOption = {
    //     name: "Scoping session",
    //     cost: 50.00,
    //     minutes: 20,
    //     hours: 0,
    //     adjustableTime: false,
    //     type: CoachRequestType.ScopingSession,
    //     calendlyUri: "scoping-session",
    // };
    public readonly customSession: ICoachOption = {
        name: "In-depth coach session",
        cost: 200.00,
        minutes: 0,
        hours: 1,
        adjustableTime: true,
        type: CoachRequestType.CustomCoachingSession,
        calendlyUri: "coaching-session",
    };
    public readonly coachOptions: ICoachOption[] = [this.customSession];

    @Input() public disabled = false;
    @Input() public readOnly = false;

    @Input() public coachOption!: ICoachOption;
    @Output() public coachOptionChange = new EventEmitter<ICoachOption>();

    // populate state from existing ICoachRequest
    @Input() public set coachRequest(coachRequest: ICoachRequest) {
        const hoursRequested = coachRequest.RequestedTimeMinutes / 60;
        const hasChanges = this.hoursRequested !== hoursRequested || this.description !== coachRequest.Description;

        this.hoursRequested = hoursRequested;
        this.description = coachRequest.Description;

        if (hasChanges) {
            this.emitChanges();
        }
    }
    @Output() public coachRequestChange = new EventEmitter<ICoachRequest>();
    @Output() public canRequestCoach = new EventEmitter<boolean>();

    public hoursRequested = 1;
    public description?: string;

    public account?: Account;
    public cardIsSet = false;

    public constructor(
        @Inject(LOCALE_ID) private localeId: string,
        private accountService: AccountService,
        private coachAccessService: CoachAccessService,
    ) {
        super();

        if (!AdaptClientConfiguration.CalendlyBaseUri) {
            throw new Error("Calendly URI is not set. Scheduling the session will not work!");
        }
    }

    public getAdjustableCost(option: ICoachOption) {
        return this.hoursRequested * option.cost;
    }

    public get totalCost() {
        if (this.account && this.account.currency) {
            const cost = this.coachOption.adjustableTime
                ? this.getAdjustableCost(this.coachOption)
                : this.coachOption.cost;

            return this.account.extensions.getTotalCost(cost);
        }

        return 0;
    }

    public get totalDisplay() {
        if (this.account && this.account.currency) {
            return formatCurrency(this.totalCost, this.localeId, this.account.currency.shortName, this.account.extensions.currencyCode, "1.0-0");
        }

        return undefined;
    }

    public ngOnInit() {
        this.accountService.getAccount().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((account) => this.account = account);

        if (!this.coachOption && !this.readOnly) {
            this.onCoachOptionChange(this.customSession);
            this.coachAccessService.getCoachSessions(true).subscribe((sessions) => {
                // if there are no sessions with empty invoices (i.e. free ones), then allow them to select a welcome session
                if (!sessions.some((s) => s.invoiceId === null)) {
                    this.coachOptions.unshift(this.welcomeSession);
                    this.coachOption = this.welcomeSession;
                } else {
                    this.coachOption = this.coachOptions[0];
                }
                this.onCoachOptionChange(this.coachOption);
            });
        } else {
            this.emitChanges();
        }
    }


    public onCardIsSetChange(cardIsSetValue: boolean) {
        this.cardIsSet = cardIsSetValue;
        this.emitChanges();
    }

    public onHoursRequestedChange(hoursRequested: number) {
        this.hoursRequested = hoursRequested;
        this.emitChanges();
    }

    public onCoachOptionChange(coachOption: ICoachOption) {
        this.coachOption = coachOption;
        this.emitChanges();
    }

    public emitChanges() {
        this.canRequestCoach.emit(this.cardIsSet || this.totalCost === 0);
        this.coachOptionChange.emit(this.coachOption);
        this.coachRequestChange.emit({
            Description: this.description,
            RequestType: this.coachOption.type,
            RequestedTimeMinutes: this.coachOption.adjustableTime
                ? this.hoursRequested * 60
                : ((this.coachOption.hours * 60) + this.coachOption.minutes),
        });
    }

    public reset() {
        this.description = undefined;
        this.hoursRequested = 1;
        this.coachOption = this.coachOptions[0];
    }
}
