import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { IConfigGroup } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";

export const allOrganisationConfigGroups = (): IConfigGroup[] => [
    {
        key: "System",
        items: [
            {
                name: "Organisation profile",
                area: ConfigArea.OrganisationProfile,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.OrganisationProfileOverview,
                supportText: "Find out more about configuring your organisation profile in adapt HQ",
            },
            {
                name: "External links",
                area: ConfigArea.ExternalLink,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.OrganisationExternalDashboard,
                enableFeatureText: "external links",
            }, {
                name: "Calendar integration",
                area: ConfigArea.CalendarIntegration,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.CalendarIntegrationOverview,
                supportText: "Find out more about calendar integration in adapt HQ",
            }, {
                name: "Pathway completion",
                area: ConfigArea.PathwayCompletion,
                accessVerifier: ConfigurationAuthService.StakeholderManagerAccess,
            },
        ],
    }, {
        key: "Organisation",
        items: [
            {
                name: "Personal profile categories",
                area: ConfigArea.PersonalProfileCategories,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.PersonalProfileCategoriesOverview,
                supportText: "Find out more about personal profile categories in adapt HQ",
            }, {
                name: "Labels",
                area: ConfigArea.Labels,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.LabelsOverview,
                supportText: "Find out more about effective use of labels in adapt HQ",
            },
        ],
    }, {
        key: "Strategy",
        items: [
            {
                name: "Purpose & vision",
                area: ConfigArea.PurposeAndVision,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.PurposeAndVision,
                enableFeatureText: "purpose & vision",
                supportArticleId: ImplementationKitArticle.PurposeOverview,
                supportText: "Find out more about purpose and vision in adapt HQ",
            }, {
                name: "Values",
                area: ConfigArea.ValuesConstitution,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.CompassValuesConstitution,
                enableFeatureText: "values",
                supportArticleId: ImplementationKitArticle.ValuesOverview,
                supportText: "Find out more about values in adapt HQ",
            }, {
                name: "Strategic working board & strategic themes",
                area: ConfigArea.StrategyBoard,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategyBoard,
                enableFeatureText: "strategic working board & strategic themes",
                supportArticleId: ImplementationKitArticle.ThemesOverview,
                supportText: "Find out more about the strategic working board and strategic themes in adapt HQ",
            }, {
                name: "Strategic inputs",
                area: ConfigArea.StrategicInputs,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategicInputs,
                enableFeatureText: "strategic inputs",
                supportArticleId: ImplementationKitArticle.InputsOverview,
                supportText: "Find out more about strategic inputs in adapt HQ",
            }, {
                name: "Bullseye",
                area: ConfigArea.Bullseye,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.Bullseye,
                enableFeatureText: "bullseye",
                supportArticleId: ImplementationKitArticle.BullseyeOverview,
                supportText: "Find out more about bullseye in adapt HQ",
            }, {
                name: "Strategic anchors",
                area: ConfigArea.StrategicAnchors,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategicAnchors,
                enableFeatureText: "strategic anchors",
                supportArticleId: ImplementationKitArticle.StrategicAnchorsOverview,
                supportText: "Find out more about strategic anchors in adapt HQ",
            }, {
                name: "Strategic goals",
                area: ConfigArea.StrategicGoals,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StrategicGoals,
                enableFeatureText: "strategic goals",
                supportArticleId: ImplementationKitArticle.StrategicGoalsOverview,
                supportText: "Find out more about strategic goals in adapt HQ",
            }, {
                name: "Objectives & key results",
                area: ConfigArea.ObjectivesAndKeyResults,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StewardshipObjectives,
                enableFeatureText: "objectives & key results",
                supportArticleId: ImplementationKitArticle.ObjectivesOverview,
                supportText: "Find out more about objectives in adapt HQ",
            },
        ],
    }, {
        key: "Teams",
        items: [
            {
                name: "Teams",
                area: ConfigArea.Team,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                supportArticleId: ImplementationKitArticle.TeamsOverview,
                supportText: "Find out more about teams in the adapt HQ",
            }, {
                name: "Actions",
                area: ConfigArea.Kanban,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StewardshipWorkKanban,
                enableFeatureText: "actions",
                supportArticleId: ImplementationKitArticle.WorkOverview,
                supportText: "Find out more about using the team action boards in adapt HQ",
            }, {
                name: "Meetings",
                area: ConfigArea.Meetings,
                accessVerifier: ConfigurationAuthService.ConfigureOrganisation,
                featureName: FeatureName.StewardshipWorkMeetings,
                enableFeatureText: "meetings",
                supportUrl: ImplementationKitArticle.WorkOverview,
                supportText: "Find out more about using team meetings in adapt HQ",
            },
        ],
    }, {
        key: "Systems",
        items: [],
    }, {
        key: "Culture",
        items: [],
    },
];
