<div class="d-flex flex-column align-items-center">
    @if (hasCadenceFeature) {
        <ng-container *adaptLoading="loading">
            <div *ngIf="hasExistingCadence"
                 class="alert alert-light mb-3">
                <h2 class="text-center mb-3">Cadence</h2>
                <adapt-review-recurrences [scheduledRecurrences]="recurrences"></adapt-review-recurrences>
            </div>
        </ng-container>

        <button *adaptIfAuthorised="StewardshipWorkMeetingsEdit"
                class="btn btn-primary"
                [adaptBlockingClick]="setCadence"
                role="button">{{hasExistingCadence ? "Update" : "Set up"}} your cadence</button>
    } @else {
        <span>This feature is enabled only for the Scale+ platform subscription.</span>
    }
</div>
