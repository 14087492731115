<div class="d-flex flex-column align-items-center my-4 overflow-hidden">
    <button *adaptIfAuthorised="ReadStrategyBoard; else featureNotEnabled"
            class="btn btn-primary px-4 mt-3"
            [adaptBlockingClick]="gotoStrategyBoard">{{buttonText}}
    </button>

    <ng-template #featureNotEnabled>
        <span>This feature is enabled only for the Scale+ platform subscription.</span>
    </ng-template>
</div>
