import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { IConfigGroup } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";

export const allPersonalConfigGroups = (): IConfigGroup[] => [
    {
        key: "Account",
        items: [
            {
                name: "Account login & security",
                area: ConfigArea.AccountSecurity,
            },
        ],
    },
    {
        key: "Personal",
        items: [
            {
                name: "Personal action boards",
                area: ConfigArea.Kanban,
                accessVerifier: KanbanAuthService.EditPersonalStewardshipKanban,
                featureName: FeatureName.StewardshipWorkKanban,
                supportArticleId: ImplementationKitArticle.WorkOverview,
                supportText: "Find out more about using the work boards in adapt HQ",
            },
            {
                name: "Calendar integration",
                area: ConfigArea.CalendarIntegration,
                accessVerifier: CommonTeamsAuthService.ViewAnyTeamMeeting,
                featureName: FeatureName.StewardshipWorkMeetings,
                supportArticleId: ImplementationKitArticle.CalendarIntegrationOverview,
                supportText: "Find out more about calendar integration in adapt HQ",
            },
        ],
    }, {
        key: "Notifications",
        items: [
            {
                name: "Stewardship activity",
                area: ConfigArea.Notifications,
                accessVerifier: KanbanAuthService.EditPersonalStewardshipKanban,
            },
        ],
    },
];
