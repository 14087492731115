import { Component, ElementRef } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategyService } from "@org-common/lib/strategy/strategy.service";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { StrategicViewOption } from "@org-common/lib/strategy/strategy-view-constants";
import { switchMap } from "rxjs/operators";

@MeetingAgendaItemComponent("adapt-themes-agenda-item")
@Component({
    selector: "adapt-themes-agenda-item",
    templateUrl: "./themes-agenda-item.component.html",
})
export class ThemesAgendaItemComponent extends BaseComponent {
    public mapViews = [StrategicViewOption.Themes];
    public readonly EditStrategyBoard = StrategyAuthService.EditStrategyBoard;
    public readonly ReadStrategyBoard = StrategyAuthService.ReadStrategyBoard;

    public constructor(
        elementRef: ElementRef,
        private strategyService: StrategyService,
    ) {
        super(elementRef);
    }

    @Autobind
    public addTheme() {
        return this.strategyService.createTheme().pipe(
            switchMap((theme) => this.strategyService.editTheme(theme)),
        );
    }
}
