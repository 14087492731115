import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStep, WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { RoleInOrganisation } from "@common/ADAPT.Common.Model/organisation/connection";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { SeedEntity } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";
import { ISeedStepData } from "../../shared/define-seed/seed.interface";
import { createActivityBriefWorkflowStep } from "../../workflow-utilities";
import { PurposeSeed, PurposeSeeds } from "./purpose-seeds";

export const defineOwnersWhyWorkflowName = "Define the owners' why";

const time = "3 hours";
const people = "Owners";
const mindset = "Reflective";

function buildOwnersWhyWorkflow(standalone: boolean) {
    return buildLocalWorkflow({
        name: defineOwnersWhyWorkflowName,
        workflowId: `${WorkflowIdentifier.DefineOwnersWhy}Define${standalone}`,
        type: WorkflowType.Workflow,
        time,
        people,
        mindset,
        showBreadcrumbs: true,
        dialogWidth: WorkflowDialogWidth.ExtraLarge,
        featuresToEnable: [FeatureName.PurposeAndVision],
        steps: [
            createActivityBriefWorkflowStep(defineOwnersWhyWorkflowName, ImplementationKitArticle.DefineOwnersWhyActivityBrief, undefined, undefined),
            ...PurposeSeeds.map((seed, i, seeds) => {
                return {
                    name: seed.question,
                    ordinal: 1 + i,
                    allowBack: true,
                    canSkip: false,
                    articleSlug: seed.articleSlug,
                    articlePosition: WorkflowStepGuidancePosition.Left,
                    componentSelector: "adapt-define-seed",
                    customData: { seed, seeds, entity: SeedEntity.Purpose } as ISeedStepData<PurposeSeed>,
                } as WorkflowStep;
            })],
    });
}

export const defineOwnersWhyWorkflow = buildOwnersWhyWorkflow(false);
export const defineOwnersWhyStandaloneWorkflow = buildOwnersWhyWorkflow(true);

export const defineOwnersWhyJourneyWorkflow = buildLocalWorkflow({
    name: defineOwnersWhyWorkflowName,
    workflowId: WorkflowIdentifier.DefineOwnersWhy,
    type: WorkflowType.Journey,
    time,
    people,
    mindset,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Uncover why the owners started the business, where they came from and what they're passionate about.",
    articleSlug: ImplementationKitArticle.DefineOwnersWhyOutcomes,
    wrapUpSlug: ImplementationKitArticle.DefineOwnersWhyWrapUp,
    category: OrganisationCategoryValue.Foundation,
    scope: WorkflowScope.Team,
    featuresToEnable: [FeatureName.PurposeAndVision],
    moduleFeaturesRequired: [FeatureName.PurposeAndVision],
    wrapUpGuidedTourIdentifier: TourIdentifier.OwnersWhyWrapUpTour,
    ordinal: 10,
    requiredRoles: [RoleInOrganisation.OwnerLeader],
    workflows: [defineOwnersWhyWorkflow],
});
