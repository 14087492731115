<div class="d-flex gap-3 flex-column">
    <!-- An experiment to remove the description field -->
    @if (false) {
    <div>
        <h3 class="required">Please describe the issue that you're facing</h3>
        <dx-text-area placeholder="Describe your issue..."
                      [disabled]="disabled"
                      [(value)]="description"
                      [inputAttr]="{ rows: 3 }">
            <dx-validator>
                <dxi-validation-rule type="required"
                                     message="This is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-area>
    </div>
    }

    <div>
        @if (readOnly) {
        <h3>Session type</h3>
        <div>
            <ng-container *ngTemplateOutlet="sessionDescription; context: { option: coachOption }"></ng-container>
            @if (coachOption.adjustableTime) {
            <div class="text-muted">
                {{hoursRequested}} hours. Subtotal:
                {{getAdjustableCost(coachOption) | currency: account?.currency?.code?.toUpperCase(): 'symbol' : '1.0-0'}}
            </div>
            }
        </div>
        } @else {
        <h3>Select session type</h3>
        <dx-radio-group [disabled]="disabled"
                        [items]="coachOptions"
                        [value]="coachOption"
                        (valueChange)="onCoachOptionChange($event)">
            <div *dxTemplate="let option of 'item'"
                 class="d-flex align-items-center gap-3 w-100">
                <div>
                    <ng-container *ngTemplateOutlet="sessionDescription; context: { option }"></ng-container>
                </div>
                @if (option.adjustableTime) {
                <div class="ms-auto d-flex align-items-center gap-3">
                    <dx-number-box format="#0 hours"
                                   [value]="hoursRequested"
                                   (valueChange)="onHoursRequestedChange($event)"
                                   [disabled]="coachOption !== option || disabled"
                                   [showSpinButtons]="true"
                                   [width]="110"
                                   [min]="1"
                                   [max]="3"></dx-number-box>
                    <div [class.text-muted]="coachOption !== option || disabled">Subtotal:
                        {{getAdjustableCost(option) | currency: account?.currency?.code?.toUpperCase(): 'symbol' : '1.0-0'}}
                    </div>
                </div>
                }
            </div>
        </dx-radio-group>
        }
    </div>

    <ng-template #sessionDescription
                 let-option="option">
        @if (option.adjustableTime) {
        {{option.name}} at {{option.cost | currency: account?.currency?.code?.toUpperCase(): 'symbol' : '1.0-0'}} / 1 hour
        } @else {
        {{option.name}} - {{option.cost | currency: account?.currency?.code?.toUpperCase(): 'symbol' : '1.0-0'}} / {{option.minutes}}
        minutes
        }
    </ng-template>

    <div>
        @if (this.totalCost === 0) {
        <h3>Payment</h3>
        <div>This session is free - upon requesting you will be prompted to schedule a date to work with one of our
            coaches.</div>
        } @else {
        @if (account && account.extensions.isBilledUsingCreditCard) {
        <h3>Payment method
            <a class="ms-2 btn btn-link small"
               [class.disabled]="disabled"
               (click)="!disabled && configureCard.showUpdateCreditCardDialog().subscribe()">Update payment
                method</a>
        </h3>

        <div [ngClass]="{'alert alert-light pt-2 pb-2 mb-0': cardIsSet}">
            <adapt-configure-credit-card #configureCard
                                         [organisationId]="account.organisationId"
                                         [allowEditing]="false"
                                         (cardIsSet)="onCardIsSetChange($event)"></adapt-configure-credit-card>
        </div>
        } @else {
        <h3>Payment method</h3>
        <div>Your organisation is not paying via credit card. You cannot request a coach at this time.</div>
        }
        }
    </div>

    @if (totalCost !== 0) {
    <div>
        You will be charged a total of <strong>{{totalDisplay}}</strong>
        @if (!account?.taxExempt) {
        (inc. GST)
        }
        upon requesting a coach.
        You will then be prompted to schedule a date to work with one of our coaches.
    </div>
    }
</div>
