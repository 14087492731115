import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { TourIdentifier } from "../../tours/tour-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../workflow-utilities";

export const developStrategicAnchorsWorkflowName = "Develop strategic anchors";

export const developStrategicAnchorsWorkflow = buildLocalWorkflow({
    name: developStrategicAnchorsWorkflowName,
    workflowId: WorkflowIdentifier.StrategicAnchors,
    type: WorkflowType.Journey,
    time: "4 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Develop the strategic anchors that will guide decision making and help uncover what makes this organisation unique.",
    category: OrganisationCategoryValue.BuildStrategy,
    featuresToEnable: [FeatureName.StrategicAnchors],
    moduleFeaturesRequired: [FeatureName.StrategicAnchors],
    ordinal: 80,
    articleSlug: ImplementationKitArticle.DevelopStrategicAnchorsOutcomes,
    wrapUpSlug: ImplementationKitArticle.DevelopStrategicAnchorsWrapUp,
    wrapUpGuidedTourIdentifier: TourIdentifier.StrategicAnchorsWrapUpTour,
    compulsoryPrerequisites: [WorkflowIdentifier.StrategicThemes],
    workflows: [
        createActivityBriefWorkflow(developStrategicAnchorsWorkflowName, WorkflowIdentifier.StrategicAnchors, undefined, ImplementationKitArticle.DevelopStrategicAnchorsMeetingDescription, ImplementationKitArticle.DevelopStrategicAnchorsMeetingPreWork),
        ...createWorkshopScheduleWorkflows(developStrategicAnchorsWorkflowName, WorkflowIdentifier.StrategicAnchors),
    ],
});
